<div class="u-margin__top--tiny">
  <ng-template ngFor [ngForOf]="repeatTimes(quantity)">
    <mat-card class="u-margin__bottom--tiny">
      <div fxLayout="row" fxLayoutGap="7px">
        <div fxFlex="1">
          <ngx-skeleton-loader
            [theme]="{ width: '4px', height: '36px', 'background-color': '#D9D9D9' }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="33">
          <ngx-skeleton-loader count="1"
            [theme]='{"width": "70px", "margin-bottom": "0px", "border-radius":"8px", "background-color": "#D9D9D9"}'></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1"
            [theme]='{"width": "216px", "margin-bottom": "0px" , "border-radius":"8px", "background-color": "#D9D9D9"}'></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1"
            [theme]='{"width": "216px", "margin-bottom": "0px" , "border-radius":"8px", "background-color": "#D9D9D9"}'></ngx-skeleton-loader>
        </div>
      </div>
    </mat-card>
  </ng-template>
</div>