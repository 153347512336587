import { Component, Input } from '@angular/core';
import { repeatTimes } from '../../shared/utilities/utilities';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {
  @Input() quantity = 5;
  repeatTimes = repeatTimes;
}
