
export class ErrorCodes {
    public static ERROR_MSG = [{
        title: 'Could not connect to Activity-feed',
        code: 500
    },
    {
        title: 'Not found', 
        code: 404
    },
    {
        title: 'Bad Request',
        code: 400
    },
    {
        title: 'User profile not found',
        code: 401
    },

]  
}